@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');

@font-face {
  font-family: "Proxima Nova Rg Regular";
  src: url("https://db.onlinewebfonts.com/t/cc44a2fd60ec971785c2e0fdf31f2bda.eot");
  src: url("https://db.onlinewebfonts.com/t/cc44a2fd60ec971785c2e0fdf31f2bda.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/cc44a2fd60ec971785c2e0fdf31f2bda.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/cc44a2fd60ec971785c2e0fdf31f2bda.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/cc44a2fd60ec971785c2e0fdf31f2bda.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/cc44a2fd60ec971785c2e0fdf31f2bda.svg#Proxima Nova Rg Regular")format("svg");
}

legend {
  float: unset;
  width: auto;
}

fieldset {
  border: 1px solid;
}

.performance-container {
  background-color: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.performance-support {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 130px 0px;
  gap: 70px;
}

#performance-title {
  font-family: 'Eastman Trial', sans-serif;
  font-size: 30px;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  color: #D6AD28;
  text-transform: uppercase;
  letter-spacing: 5px;
  border: 1px solid #D6AD28;
  margin-bottom: 0px;
}

#performance-content {
  color: #FFF;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.performance-models {
  display: flex;
  gap: 30px;
}

.performance-model-card {
  padding: 20px;
  border-color: #D6AD28;
  width: 384px;
  text-align: center;
  display: flex;
  align-items: center;
}

.performance-model-card legend {
  color: #FFF;
  text-align: center;
  font-family: "Proxima Nova Rg Regular";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 0px 20px;
}

.performance-model-card span {
  color: #FFF;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media (max-width: 768px) {
  #performance-content {
    font-size: 25px;
  }

  .performance-model-card {
    width: 350px;
  }

  .performance-model-card legend {
    font-size: 25px;
  }

  .performance-model-card span {
    font-size: 15px;
    line-height: 30px;
  }
}

@media (max-width: 712px) {
  #performance-title {
    font-size: 20px;
  }

  #performance-content {
    font-size: 20px;
    line-height: 30px;
  }

  .performance-models {
    gap: 15px;
  }

  .performance-model-card {
    width: 325px;
  }
}

@media (max-width: 640px) {
  .performance-support {
    gap: 45px;
  }

  #performance-content {
    font-size: 15px;
  }

  .performance-model-card {
    width: 280px;
  }

  .performance-model-card span {
    line-height: 25px;
  }
}

@media (max-width: 430px) {
  #performance-title {
    text-align: center;
    font-size: 20px;
  }

  #performance-content {
    line-height: 25px;
  }

  .performance-models {
    flex-direction: column;
  }
}