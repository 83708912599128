.span-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;

  margin-right: 17.2%;
}

.firstBannerSupport {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.firstBanner {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.firstBanner span {
  font-family: "Eastman Trial", sans-serif;
  padding: 10px;
  background-color: #8e7936;
  font-size: 25px;
  color: white;
}

/*  */
.span-container span {
  font-family: "Eastman Trial", sans-serif;
  padding: 50px;
  background-color: transparent;
  font-size: 45px;
  color: white;
  white-space: pre-line;
  border: 1px solid white;
}

.third-banner-texts {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 200px;
  align-items: flex-start;
  top: 25%;
  left: 10%;
}

.third-upper-banner-text {
  font-family: "Eastman Trial", sans-serif;
  color: black;
  font-size: 40px;
  white-space: pre-line;
}

#secondBannerTextTitle {
  font-size: 72px;
}

.third-down-banner-text {
  font-family: "Eastman Trial", sans-serif;
  color: black;
  white-space: pre-line;

  font-size: 20px;
}

.second-banner-text-container {
  position: absolute;
  display: flex;
  left: 12%;
  flex-direction: column;
  align-items: flex-start;
  top: 30%;
}

#second-banner-up-text {
  color: white;
  font-size: 31px;
  font-weight: 400;
  white-space: pre-line;
  font-family: "Eastman Trial", sans-serif;
}

#second-banner-down-text {
  color: black;
  font-size: 23px;
  text-align: center;
  white-space: pre-line;
}

#second-banner-icon {
  width: 148px;
  height: 154px;
}

@media (max-width: 1920px) {
  .third-upper-banner-text {
    font-size: 30px;
  }

  .third-banner-texts {
    gap: 130px;
  }
}

@media (max-width: 1440px) {
  .span-container span {
    font-size: 30px;
  }

  .second-banner-text-container {
    top: 25%;
  }

  .third-banner-texts {
    gap: 130px;
    left: 6%;
  }

  .third-upper-banner-text {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {

  .firstBanner span {
    font-size: 20px;
  }

  #secondBannerTextTitle {
    font-size: 42px;
  }

  .span-container span {
    font-size: 20px;
  }

  #second-banner-icon {
    width: 100px;
    height: 100px;
  }

  #line-elipse {
    width: 40%;
  }

  .second-banner-text-container {
    right: 5%;
  }

  #second-banner-down-text {
    font-size: 22px;
  }

  .third-upper-banner-text {
    font-size: 25px;
  }

  .third-banner-texts {
    gap: 70px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute !important;
    top: var(--swiper-navigation-top-offset, 20%);
  }
}

@media (max-width: 950px) {
  .span-container span {
    padding: 30px;
  }

  #secondBannerTextTitle {
    font-size: 35px;
  }

  #second-banner-up-text {
    font-size: 26px;
  }

  .second-banner-text-container {
    right: 9%;
  }

  .third-banner-texts {
    gap: 40px;
  }

  .third-upper-banner-text {
    font-size: 22px;
  }
}

@media (max-width: 820px) {

  .firstBanner span {
    font-size: 17px;
  }

  #secondBannerTextTitle {
    font-size: 32px;
  }

  #second-banner-up-text {
    font-size: 24px;
  }

  .span-container span {
    font-size: 17px;
  }

  #second-banner-down-text {
    font-size: 16px;
  }

  .third-banner-texts {
    top: 18%;
  }

  .third-upper-banner-text {
    font-size: 19px;
  }
}

@media (max-width: 640px) {
  .second-banner-text-container {
    top: 18%;
  }

  #secondBannerTextTitle {
    font-size: 26px;
  }

  #second-banner-up-text {
    font-size: 18px;
  }

  #second-banner-icon {
    width: 70px;
    height: 70px;
  }

  .span-container span,
  .firstBanner span {
    font-size: 14px;
  }

  .second-banner-text-container {
    right: 5%;
  }

  #second-banner-up-text {
    font-size: 25px;
  }

  #second-banner-down-text {
    font-size: 14px;
  }

  .third-down-banner-text {
    font-size: 15px;
  }

  .third-upper-banner-text {
    font-size: 16px;
  }
}

@media (max-width: 430px) {
  .span-container span,
  .firstBanner span {
    max-width: 100%;
  }

  #second-banner-icon {
    display: none;
  }

  .span-container span {
    max-width: 100%;
    font-size: 12px;
  }

  #second-banner-up-text {
    font-size: 15px;
  }

  #second-banner-down-text {
    max-width: 60%;
  }

  .second-banner-text-container {
    right: 0%;
  }

  .third-banner-texts {
    top: 10%;
    gap: 12px;
    left: 10%;
  }

  .third-upper-banner-text {
    font-size: 12px;
    max-width: 60%;
  }

  .third-down-banner-text {
    font-size: 12px;
  }
}

@media (max-width: 390px) {

  .firstBanner span {
    max-width: 80%;
  }
  #secondBannerTextTitle {
    font-size: 22px;
  }

  #second-banner-up-text {
    font-size: 14px;
  }

  .second-banner-text-container {
    right: -3%;
  }

  #second-banner-down-text {
    font-size: 13px;
  }

  .third-banner-texts {
    line-height: 1.3;
  }

  .third-banner-texts {
    top: 10%;
    gap: 9px;
    left: 11%;
  }
}
