footer {
  background-color: #D6AD28;
  display: flex;
  padding: 30px 0px;
  gap: 20px;
  justify-content: center;
}

footer span {
  color: #303030;
  font-family: 'Proxima Nova Condensed', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  position: relative;
}


.hoverFooter::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #d2cbb8;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.hoverFooter:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

footer a {
  text-decoration: none;
  color: #303030;
}

@media (max-width: 640px) {
  footer span {
    font-size: 15px;
  }
}

@media (max-width: 430px) {
  footer span {
    font-size: 10px;
  }
}

@media (max-width: 390px) {
  footer span {
    font-size: 12px;
    line-height: 1.2;
  }
}