.products-container {
  background: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px;
  gap: 80px;
}

#products-title {
  background: #D6AD28;
  padding: 10px;
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 7px;
}

.products-icons-container {
  display: flex;
  flex-direction: column;
  gap: 65px;
}

#products-icons-top,
#products-icons-bottom {
  display: flex;
  flex: 1;
  gap: 70px;
  justify-content: space-evenly;
  align-items: flex-start;
}

.products-icons {
  height: 100px;
}

.products-footer {
  padding: 40px;
  border: 1px solid #D6AD28;
  text-align: center;
}

#footer-content {
  white-space: pre-line;
  color: #FFF;
  text-align: center;
  font-family: Calibri;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1024px) {
  .products-icons {
    height: 100px;
  }

  .products-footer {
    width: 85%;
  }

}

@media (max-width: 950px) {
  .products-icons {
    height: 80px;
  }
}

@media (max-width: 768px) {

  #products-icons-top,
  #products-icons-bottom {
    gap: 40px;
  }
}

@media (max-width: 640px) {
  .products-icons-container {
    gap: 45px;
  }

  #products-icons-top,
  #products-icons-bottom {
    gap: 25px;
  }

  #footer-content {
    font-size: 15px;
  }
}

@media (max-width: 430px) {

  #products-icons-top,
  #products-icons-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

.icon-container {
  text-align: center;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
}

#icon-text {
  color: white;
  margin-top: 10px;
  font-family: 'Proxima Nova Rg Regular', sans-serif;
}