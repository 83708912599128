.address-container {
  background-color: #303030;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  padding: 60px 0px;
}

.address-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

}

.address-up {
  color: #FFF;
  font-family: "Proxima Nova Rg Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.address-down,
#cnpj-span {
  color: #FFF;
  display: flex;
  font-family: "Proxima Nova Rg Regular";
  font-size: 20px;
  gap: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  justify-content: center;
  align-items: center;
}

.address-down img {
  width: 17px;
  height: 17px;
  filter: invert(66%) sepia(100%) saturate(366%) hue-rotate(1deg) brightness(90%) contrast(88%);
}


@media (max-width: 640px) {
  #address-logo {
    width: 250px;
  }
  .address-content {
    width: 85%;
  }
  .address-up, .address-down, #cnpj-span {
    line-height: 1.2;
    text-align: center;
  }
  .address-down {
    text-align: left;
    font-size: 16px;
  }
}

@media (max-width: 430px) {

  .address-up,
  .address-down,
  #cnpj-span {
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .address-up,
  .address-down,
  #cnpj-span {
    font-size: 12px;
  }
}

@media (max-width: 340px) {
  .address-down {
    font-size: 10px;
  }
}

#address-logo {
  width: 240px;
}