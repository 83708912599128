.bm-burger-button {
  /* position: fixed; */
  display: none;
  position: absolute;
  width: 15px;
  height: 12px;
  right: 36px;
  top: 30px;
}

.bm-burger-bars {
  background: #e1e1e1;
}

.bm-burger-bars-hover {
  background: #ffffff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background-color: #00000000 !important; 
}

@media (max-width: 640px) {
  .bm-burger-button {
    display: block;
  }
}

.slider-text {
  color: white;
  text-decoration: none;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
}