@import url('https://fonts.cdnfonts.com/css/eastman-trial');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-header {
  display: flex;
  flex-direction: column;
  background-color: #303030;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;
}

.header-support {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding: 20px 0px;
  align-items: center;
}

#main-logo {
  height: 40px;
}

.header {
  display: flex;
  gap: 15px;
}

.link-header {
  color: white;
  text-decoration: none;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 14px;
  position: relative;
}

.link-header::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #d2cbb8;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.link-header:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.header-flags {
  display: flex;
  gap: 10px;
}

.header-flag {
  height: 30px;
  width: 35px;
}

* {
  --swiper-theme-color: #d2cbb8;
}


@media (max-width: 1024px) {
  .link-header {
    font-size: 12px;
  }
}

@media (max-width: 950px) {
  .header-support {
    gap: 20px;
  }

  #main-logo {
    height: 30px;
  }
}

@media (max-width: 768px) {
  #main-logo {
    height: 25px;
  }

  .link-header {
    font-size: 12px;
  }

  .header-flag {
    width: 20px;
  }

  .header {
    gap: 10px;
  }
}

@media (max-width: 712px) {
  .header-flag {
    width: 20px;
  }
}

@media (max-width: 640px) {
  .header {
    gap: 5px;
  }

  .link-header {
    display: none;
  }
}

@media (max-width:430px) {}