.service-container {
  background: #FFF;
  display: flex;
}

#service-image {
  width: 30%;
  height: auto;
  object-fit: cover;
}

.service-support {
  display: flex;
  width: 80%;
  justify-content: center;
}

.service-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 90px;
  padding: 70px 0px;
  justify-content: center;
}

#service-title {
  padding: 10px;
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 7px;
  border: 1px solid #303030;
}

.service-subtitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  text-align: center;
}

#service-subtitle-principal {
  color: #D6AD28;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

#service-subtitle-content {
  color: #303030;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.service-resume {
  display: flex;
  width: 70%;
  gap: 10px;
}

.service-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  white-space: pre-line;
}

.service-card-title {
  color: #D6AD28;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.service-card-content {
  color: #303030;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.service-card-space {
  height: 100%;
  width: 2px;
  background: #252525;
}

@media (max-width:1440px) {
  .service-card-title {
    color: #D6AD28;
    text-align: center;
    font-family: 'Eastman Trial', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  /* #service-image {
    width: auto;
    height: auto;
  } */
}

@media (max-width: 1366px) {
  .service-resume {
    width: 80%;
  }

}

@media (max-width: 1280px) {
  .service-content {
    width: auto;
    gap: 40px;
  }

  #service-image {
    width: 50%;
  }
}

@media (max-width: 950px) {
  .service-container {
    justify-content: center;
  }
  #service-image {
    display: none;
  }
}

@media (max-width: 712px) {
  .service-support {
    width: 90%;
  }
}

@media (max-width: 430px) {
  #service-title {
    font-size: 25px;
  }
  #service-subtitle-principal {
    font-size: 20px;
  }
  #service-subtitle-content {
    font-size: 15px;
    line-height: 22px;
  }
  .service-card-title {
    line-height: 22px;
  }
  .service-card-content {
    line-height: 22px;
  }
}