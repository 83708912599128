@font-face {
  font-family: "Eastman Roman Trial Light";
  src: url("https://db.onlinewebfonts.com/t/491b02750d328742a4b176368e6ef7a2.eot");
  src: url("https://db.onlinewebfonts.com/t/491b02750d328742a4b176368e6ef7a2.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/491b02750d328742a4b176368e6ef7a2.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/491b02750d328742a4b176368e6ef7a2.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/491b02750d328742a4b176368e6ef7a2.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/491b02750d328742a4b176368e6ef7a2.svg#Eastman Roman Trial Light")format("svg");
}

@font-face {
  font-family: 'Eastman Roman Trial';
  src: url('../assets/EastmanRomanTrial-Regular.otf');
}

@font-face {
  font-family: 'Eastman Roman Trial Bold';
  src: url('../assets/EastmanRomanTrial-Bold.otf');
}

.about-container {
  background: #303030;
  display: flex;
  justify-content: space-between;
}

#about-title {
  font-family: "Eastman Roman Trial";
  font-size: 30px;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  color: #D6AD28;
  letter-spacing: 5px;
  text-transform: uppercase;
  border: 1px solid #D6AD28;
}

.about-image-container {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  width: 40%;
  /* height: 920px; */
}

#button-about {
  padding: 10px 131px;
  background-color: #D6AD28;
  border-radius: 15px;
  margin-bottom: 64px;
  border: none;
  color: #303030;
  text-align: center;
  font-family: var(--font-eastman);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#button-about:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 26%;
  right: -20px;
  transition: 0.5s;
}

#button-about:hover {
  transform: scale(1.1);
  /* padding-right: 10px;
  padding-left:10px; */
}

#button-about:hover:after {
  opacity: 1;
  right: 10px;
}

:root {
  --font-eastman: 'Eastman Roman Trial', sans serif;
}

.about-left {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.about-support {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

#about-paragraph {
  color: #FFF;
  font-family: var(--font-eastman);
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-top: 20px;
}

#about-content {
  color: #FFF;
  font-family: var(--font-eastman);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}

#about-footer {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

#about-footer span {
  color: #FFF;
  font-family: 'Eastman Roman Trial Bold';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.inner-about-footer {
  display: flex;
  gap: 20px;
}

/* #last-footer svg {
  margin-left: -1.5%;
} */

.container-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

#about-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.content-about-right {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about-right-span {
  color: white;
  font-family: var(--font-eastman);
  font-size: 25px;
  text-align: center;
  white-space: pre-line;
}



@media (max-width: 1440px) {
  .about-support {
    width: 80%;
  }

  #about-paragraph {
    margin-top: 40px;
  }
}

@media (max-width: 1366px) {
  #about-footer span {
    font-size: 17px;
  }
}

@media (max-width: 1024px) {
  .about-left {
    padding: 9% 0px;
  }

  #about-title {
    font-size: 24px;
  }

  #about-paragraph {
    margin-top: 18px;
    font-size: 20px;
    line-height: 1.5;
  }

  #about-content {
    font-size: 16px;
    line-height: 28px;
  }

  #about-footer span {
    font-size: 12px;
    line-height: 1.6;
  }

  .about-image-container {
    height: 700px;
    width: 50%;
  }
}

@media (max-width: 950px) {
  #about-content {
    font-size: 13px;
  }
}

@media (max-width: 820px) {
  #about-content {
    line-height: 25px;
  }

}

@media (max-width: 712px) {
  .about-support {
    width: 85%;
  }
}

@media (max-width: 640px) {
  .about-image-container {
    display: none;
  }

  .about-support {
    align-items: center;
  }
}