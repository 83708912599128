.principal-container {
  display: flex;
  height: 850px;
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: var(--background-gray-color);
}

#image-principal{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: '' !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: '' !important;
} */

/* .swiper-button-prev,
.swiper-button-next {
  position: absolute !important;
  top: 50%;
  width: calc(var(--swiper-navigation-size)/ 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: 0;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */

;.swiper-button-prev {
  margin-left: 5%
}

.swiper-button-next {
  margin-right: 5%
}

@media (max-width: 820px) {

  /* .swiper-button-prev,
  .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 20%);
  } */
}

.slide-support {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 640px) {

  /* .swiper-button-next,
  .swiper-button-prev {
    display: none;
  } */
}

@media (max-width: 1024px) {

  /* .swiper-button-prev,
  .swiper-button-next {
    top: 50% !important;
  } */
}

@media (max-width: 640px) {

  /* .swiper-button-prev,
  .swiper-button-next {
    top: 50% !important;
  } */
}

@media (max-width: 820px) {

  /* .swiper-button-prev,
  .swiper-button-next {
    top: 50% !important;
  } */
}