.benefits-container {
  background: #E2E6EF;
  display: flex;
}

.benefits-left {
  width: 50%;
  display: flex;
  padding: 2% 0;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
}

#benefits-title {
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  text-align: center;

  line-height: 1.5;
}

.benefits-cards-container {
  display: flex;
  gap: 10px;
  margin-left: 25%;
  flex-direction: column;
  /* align-items: center; */
}

.benefits-cards-org {
  display: flex;
  gap: 10px;
}

#benefits-cards-span {
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  width: 75%;
  margin-bottom: 5%;
}

.benefits-card {
  background-color: white;
  padding: 30px 43px;
  border-radius: 25px;
  height: auto;
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.benefits-card-title {
  color: #303030;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.benefits-card-content {
  color: #303030;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}



#benefits-image {
  width: 50%;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  /* width: 40%; */
  /* height: 100%; */
}

@media (max-width: 1440px) {
  .benefits-card-content {
    color: #303030;
    text-align: center;
    font-family: 'Eastman Trial', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .benefits-card {
    background-color: white;
    padding: 30px 15px;
    border-radius: 25px;
    height: auto;
    width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }



  #benefits-title {
    font-size: 25px;
  }

  .benefits-cards-container {
    margin-left: 20%;
  }

  .benefits-card {
    padding: 21px 15px;
  }

  .benefits-card-title {
    font-size: 15px;
  }
}

.benefits-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.benefits-fill {
  height: 1px;
  width: 60%;
  background-color: #000;
}

@media (max-width: 1280px) {
  .benefits-left {
    gap: 20px;
  }

  .benefits-cards-container {
    margin-left: 20%;
  }
}

@media (max-width: 1024px) {
  #benefits-title {
    font-size: 30px;
  }

  .benefits-cards-container {
    margin-left: 40%;
  }

  .benefits-card {
    padding: 14px 10px;
    width: 250px;
  }

  .benefits-card-title {
    font-size: 15px;
    line-height: 1.2;
  }

  .benefits-card-content {
    font-size: 15px;
    text-align: justify;

  }

  #benefits-image {
    align-items: flex-end;
  }
  .content-about-right{
    margin-left: 100px;
  }
}

@media (max-width: 950px) {
  .benefits-container {
    justify-content: center;
    padding: 10px 0px 40px 0px;
  }

  #benefits-image {
    display: none;
  }

  .benefits-cards-container {
    margin: 0;
  }

  #benefits-cards-span {
    width: 100%;
  }

}

@media (max-width: 768px) {
  #benefits-image {
    display: none;
  }
}

@media (max-width: 640px) {
  #benefits-title {
    font-size: 20px;
  }
}

@media (max-width: 430px) {
  .benefits-cards-org {
    display: flex;
    flex-direction: column;
  }
}