.contact-container {
  background: #E2E6EF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 120px;
}

.contact-support {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0px;
  background: white;
}

.contact-text {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: flex-start;
  gap: 15px;
}

#contact-image {
  height: 36px;
}

.contact-text-fill {
  height: 1px;
  width: 100%;
  background: #303030;
}

#contact-text {
  color: #8D8D8D;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.contact-button {
  display: flex;
  width: 15%;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#contact-button {
  display: flex;
  text-align: center;
  height: 56px;
  padding: 20px;
  border-radius: 35px;
  background: #D6AD28;
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  border: none;
  line-height: 28px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s;
}

#contact-button:after {
  content: '»';
  position: absolute;
  opacity: 0;  
  top: 26%;
  right: -20px;
  transition: 0.5s;
}

#contact-button:hover{
  padding: 22px;
  transform: scale(1.1);
}

#contact-button:hover:after {
  opacity: 1;
  right: 5px;
}

@media (max-width: 1440px) {
  .contact-button {
    width: 20%;
  }
}

@media (max-width: 1024px) {
  .contact-support {
    width: 100%;
  }

}

@media (max-width: 768px) {
  .contact-support {
    justify-content: space-evenly;
  }

  #contact-image {
    height: 20px;
  }

  #contact-button {
    font-size: 15px;
  }
}

@media (max-width: 712px) {
  #contact-button {
    font-size: 13px;
  }
}

@media (max-width: 640px) {
  .contact-button {
    width: 30%;
  }

  #contact-button {
    padding: 10px;
    height: 25px;
    margin-top: 20px;
  }

  #contact-text {
    font-size: 20px;
  }
}

@media (max-width: 430px) {
  #contact-button {
    font-size: 13px;
    width: 100%;
  }

  #contact-image {
    height: 15px;
  }

  #contact-text {
    font-size: 12px;
  }

  .contact-support {
    flex-direction: column;
  }

  .contact-button {

    width: 50%;
  }
}