.finTechBoostContainer {
  padding-top: 5%;
  background: linear-gradient(to bottom,
      #E2E6EF 0%,
      #E2E6EF 50%,
      #303030 50%,
      #303030 100%);
  display: flex;
}

.finTechBoostRightContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.finTechBoostTopSupport span {
  font-family: "Eastman Roman Trial";
  font-size: 18px;
  line-height: 1;
  color: #000000;
}

.finTechBoostTopSupport,
.finTechBoostBottomSupport {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 0;
  width: 70%;
}

#finTechBoostNomalText {
  font-size: 21px;
  font-family: 'Eastman Roman Trial';
}

.finTechBoostBottomSupport span {
  font-family: "Eastman Roman Trial Bold";
  font-size: 30px;
  color: white;
  line-height: 1;
  margin-bottom: 5%;
}

#finTechImage {
  width: 50%;
}

#titleFinTechBoost {
  font-size: 30px;
  font-family: 'Eastman Roman Trial Bold';
  line-height: 1;
}

.finTechBoostBottomSupport img {
  width: 100px;
  height: 100px;
}

#support-div-fintechboost {
  width: 80%;
  margin-top: 4%;
}


@media (max-width: 1550px) {

  .finTechBoostBottomSupport span {
    font-size: 25px;
  }
}

@media (max-width: 1440px) {
  .finTechBoostBottomSupport span {
    font-size: 22px;
  }

  #titleFinTechBoost {
    font-size: 26px;
  }
}

@media (max-width: 1280px) {

  .finTechBoostTopSupport,
  .finTechBoostBottomSupport {

    width: 75%;
  }
}

@media (max-width: 1024px) {
  #titleFinTechBoost {
    font-size: 24px;
  }
}

@media (max-width: 950px) {
  .finTechBoostContainer {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .finTechBoostBottomSupport img {
    display: none;
  }

  #support-div-fintechboost {
    width: 100%
  }

  .finTechBoostTopSupport,
  .finTechBoostBottomSupport {
    width: 90%;
  }

  .finTechBoostTopSupport span {
    font-size: 16px;
  }

  .finTechBoostBottomSupport span {
    font-size: 18px;
  }

  #titleFinTechBoost {
    font-size: 18px;
  }

  #finTechBoostNomalText {
    font-size: 16px;
    font-family: 'Eastman Roman Trial';
  }
}

@media (max-width: 820px) {

  .finTechBoostTopSupport,
  .finTechBoostBottomSupport {
    width: 95%;
  }

  .finTechBoostBottomSupport span {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  #titleFinTechBoost {
    font-size: 14px;
  }

  .finTechBoostTopSupport span {
    font-size: 14px;
  }

  .finTechBoostTopSupport,
  .finTechBoostBottomSupport {
    line-height: 1;
  }

  .finTechBoostTopSupport span {
    font-size: 12px;
  }

  .finTechBoostBottomSupport span {
    font-size: 14px;
  }

  #finTechBoostNomalText {
    font-size: 14px;
    font-family: 'Eastman Roman Trial';
  }
}

@media (max-width: 440px) {
  #finTechImage {
    display: none;
  }
  .finTechBoostRightContainer {
    width: 80%;
    /* gap: 5% */
  }
  .finTechBoostContainer {
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .finTechBoostTopSupport,
  .finTechBoostBottomSupport {
    text-align: center;
    padding-bottom: 5%;
  }

  .finTechBoostBottomSupport {
    padding-top: 10%;
  }

}