.questions-container {
  background: #E2E6EF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}

#questions-title {
  text-align: center;
  padding: 10px;
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 7px;
  border: 1px solid #303030;
}

.questions-content {
  display: flex;
  gap: 40px;
  width: 80%;
  padding: 90px 30px;
}

.questions-accordion {
  width: 100%;
}

.accordion-button:not(.collapsed) {
  background: #D6AD28;
  color: white;
}

.accordion-item {
  margin-bottom: 5px;
  border-radius: 10px;

}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@media (max-width: 950px) {
  #questions-img {
    display: none;
  }
}

@media (max-width: 712px) {
  #questions-title {
    font-size: 24px;
  }
  .questions-content {
    padding: 78px 22px;
  }
}

@media (max-width: 430px) {
  #questions-title {
    width: 70%;
    font-size: 20px;
  }
}