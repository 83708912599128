.form-container {
  display: flex;
  align-items: center;
  background-color: #E2E6EF;
}

.form-left-div {
  height: 800px;
}

#form-image {
  max-height: 100%;
}

.form-label {
  display: flex;
  flex-direction: column;
  font-family: 'Eastman Trial', sans-serif;
}

.work-form-content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.work-form-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.work-form-input {
  background-color: #fff;
  padding: 5px 4px;
  width: 447px;
  height: 41px;
  border: none;
  margin-bottom: 20px;
  padding: 10px 10px;
  color: #b4b7b9;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 10px;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.buttons-container {
  display: flex;
  gap: 60px;
  font-family: 'Eastman Trial', sans-serif;
}

.form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 35px;
  text-align: center;
  padding: 60px 0px;
}

input::placeholder,
textarea::placeholder {
  color: #b4b7b9;
  font-family: 'Eastman Trial', sans-serif;
}

#work-form-message {
  height: 200px;
  color: #b4b7b9;
}

#work-form-button {
  padding: 6px 56px;
  border: none;
  border-radius: 25px;
  color: #000;
  background-color: #D6AD28;
}

#work-form-button:hover {
  background-color: #9b7c13a7;
}

@media (max-width: 1440px) {
  .work-form {
    gap: 40px;
  }
}

@media (max-width: 1024px) {
  .form-left-div {
    display: none;
  }

  .work-form-content {
    padding: 80px 0px;
  }

  .work-support {
    gap: 20px;
  }

  #work-header-title {
    font-size: 24px;
  }

  .work-form {
    justify-content: center;
  }
}



@media (max-width: 767px) {
  .work-form-input {
    width: 350px;
  }
}

@media (max-width: 390px) {
  .buttons-container {
    gap: 30px;
  }
}


@media (max-width: 350px) {
  .work-form-input {
    width: 300px;
  }
}

buttons-container button:hover {
  transition: all 0.1s ease-in-out;
  transform: scale(1.1);
  cursor: pointer;
}

buttons-container button:not(:hover) {
  transform: scale(1.2);
}

#sender-email-success {
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 16px;
}