.public-container {
  position: relative;
  display: flex;
  background: #E2E6EF;
  padding-bottom: 2%;
}

#public-image {
  display: block;
  max-width: 50%;
  height: 100%;
}

#public-title {
  font-family: 'Eastman Trial', sans-serif;
  font-size: 30px;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  color: #303030;
  letter-spacing: 5px;
  border: 1px solid #303030;

}

.public-content {
  position: relative;
  margin-top: 5%;
  /* margin-left: 63%; */
  text-align: center;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.public-cards-container {
  margin-left: 10%;
}

#public-cards-cards {
  display: flex;
  gap: 2%;
  font-size: 17px;
  margin-left: -40%;
}

#public-cards-text-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  width: 80%;
}

#public-cards-text-title {
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  text-align: justify;
  white-space: pre-line;
}

#public-cards-text-content {
  color: #303030;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  white-space: pre-line;
  margin-bottom: 5%;
}


.public-card {
  background-color: white;
  padding: 30px 43px;
  border-radius: 25px;
  height: auto;
  width: 400px;

}

.public-card-title {
  color: #303030;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.public-card-content {
  color: #303030;
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

@media (max-width: 1840px) {
  .public-card-content {
    font-size: 14px;
  }

  .public-card {
    width: 440px;
    padding: 30px 35px;
  }

  .public-cards-container {
    margin-left: 5%;
  }
}

@media (max-width: 1524px) {
  .public-card {
    padding: 15px 35px;
  }

  .public-card-title {
    font-size: 18px;
  }
}

@media (max-width: 1440px) {
  .public-cards-container {
    display: flex;
    gap: 2%;
    font-size: 12px;
    flex-direction: column;
  }

  #public-cards-text-title {
    font-size: 25px;
  }

  #public-cards-cards {

    margin-left: -45%;
  }

  #public-cards-text-div {
    width: 70%;
  }

  .public-card-content {
    color: #303030;
    text-align: center;
    font-family: 'Eastman Trial', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  .public-card-title {
    color: #303030;
    text-align: center;
    font-family: 'Eastman Trial', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}

@media (max-width: 1366px) {
  .public-content {
    top: 3%;
  }

  #public-cards-text-title {
    font-size: 22px;
  }

  #public-cards-text-div {
    width: 60%;
  }
}

@media (max-width: 1320px) {
  .public-content {
    gap: 15px;
  }

}

@media (max-width: 1024px) {
  #public-title {
    font-size: 20px;
  }

  #public-cards-text-title {
    font-size: 18px;
  }

  .public-card {
    padding: 10px 20px;
    width: 375px;
  }

  .public-card-title {
    font-size: 16px;
    text-align: justify;
  }

  .public-card-content {
    font-size: 14px;
    text-align: justify;
  }
}

@media (max-width: 950px) {
  .public-container {
    display: flex;
    align-items: center;
    justify-content: center;

  }


  #public-cards-text-title {
    font-size: 22px;
  }

  #public-cards-text-content {
    font-size: 18px;
    text-align: center;
  }

  #public-image {
    display: none;
  }

  #public-cards-cards {
    margin: 0;
  }

  #public-cards-text-div {
    width: 90%;
  }

  #public-cards-text-title {
    text-align: center;
  }

  .public-cards-container {
    align-items: center;
  }

  .public-content {
    position: relative;
    align-items: center;
    top: auto;
    margin-left: 0;
    padding: 50px 0px;
  }

  .public-cards-container {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .public-card {
    width: 315px;
  }
}

@media (max-width: 640px) {
  .public-card {
    width: 280px;
  }
}

@media (max-width: 430px) {
  .public-cards-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  #public-cards-cards {
    flex-direction: column;
    gap: 15px
  }
}